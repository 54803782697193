const API_BACKEND = process.env.REACT_APP_BACK_END_URL;

export async function get_eligibity(kw,page=1){
    var requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: {
            'Content-Type': 'application/json', 
            'Authorization': `Bearer ${localStorage.tk_r4g}`
        }
    };
      
    const data = await fetch(`${API_BACKEND}/log/check?page=${page}&limit=10&kw=${kw}`, requestOptions);
    const resp = await data.json();
    return resp;
}

export async function authenticate(passphrase){
    var requestOptions = {
        method: 'POST',
        redirect: 'follow',
        headers: {'Content-Type': 'application/json' },
        body: JSON.stringify({ passphrase: passphrase })
    };

    const data = await fetch(`${API_BACKEND}/log/auth`, requestOptions);
    const resp = await data.json();

    return resp;
}