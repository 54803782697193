import Layout from "../../shared_components/Layout";
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import DialogModal from "../../shared_components/DialogModal";
import { useState, useEffect, Fragment } from "react";
import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon, DocumentArrowDownIcon } from '@heroicons/react/24/outline';

import { get_eligibity } from "../../net/eligibility";
import humanizeDuration from "humanize-duration";

let lock_data_check = 0;
let current_page = 1;
let current_application = 0;

export default function ExportSalesforce() {
    if (!localStorage.tk_r4g) {
        window.location.href = "/login";
    }

    const [start_date, set_start_date] = useState("");
    const [end_date, set_end_date] = useState("");

    const handleChangeDate = (e, type) => {
        console.log(e.target.value);
        if(type === "start_date") {
            set_start_date(e.target.value);
        } else {
            set_end_date(e.target.value);
        }
    }

    const handleExport = () => {
        if(!start_date){
            alert("Please select start date");
            return;
        }else if(!end_date){
            alert("Please select end date");
            return;
        }

        const url = `${process.env.REACT_APP_BACK_END_URL}/exportsf/${encodeURI(start_date)}/${encodeURI(end_date)}`;
        window.location.href = url;
    }

    return (
        <>
            <Layout title="Generate NLAD Spreadsheet" className="">
                <div className="flex flex-col w-full h-full">
                    <div className="md:max-w-md lg:max-w-lg border md:flex px-2 py-2">
                        <div className="flex-auto">
                            <div className="text-sm">Start date</div>
                            <div><input onChange={(e) => handleChangeDate(e, "start_date")} type="date" className="border px-2 py-2 my-2 rounded-md" /></div>
                        </div>
                        <div className="flex-auto">
                            <div className="text-sm">End date</div>
                            <div><input onChange={(e) => handleChangeDate(e, "end_date")} type="date" className="border px-2 py-2 my-2 rounded-md" /></div>
                        </div>
                        <div className="flex-grow">
                            <div className="text-sm">&nbsp;</div>
                            <div><button onClick={handleExport} className="border px-4 py-2 my-2 rounded-md bg-sky-500 text-white"><DocumentArrowDownIcon className="h-5 w-5 inline" /> Export</button></div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
}