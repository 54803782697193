import Layout from "../../shared_components/Layout";
import { ChevronDownIcon } from '@heroicons/react/20/solid'

const people = [
    {"_id":
        {"$oid":"638e156dc8baf28a5f8f2445"},
        "firstName":"El",
        "middleName":"Test",
        "lastName":"Esfs",
        "address":"Nyerere Road",
        "state":"AK",
        "city":"Dar Es Salam",
        "zipCode":"32323",
        "urbanizationCode":"",
        "dob":"2019-02-02",
        "ssn4":"3233",
        "tribalId":"",
        "bqpFirstName":"",
        "bqpLastName":"",
        "bqpDob":"",
        "bqpSsn4":"",
        "bqpTribalId":"",
        "alternateId":"",
        "bqpAlternateId":"",
        "eligibilityProgramCode":"E2",
        "publicHousingCode":"",
        "consentInd":"Y",
        "contactPhoneNumber":"3232323443",
        "contactEmail":"dfsljkj@lkfslkjfds",
        "contactAddress":"Nyerere Road",
        "contactCity":"Dar Es Salam",
        "contactState":"AK",
        "contactZipCode":"32323",
        "contactUrbCode":"",
        "repId":"",
        "repNotAssisted":"1",
        "carrierUrl":"https://rural4g.com",
        "status":"PENDING_RESOLUTION",
        "application_id":"B14872-05122",
        "activeSubscriber":"N",
        "usacAPIResponse":{
            "applicationId":"B14872-05122",
            "eligibilityCheckId":"2A85924BFE25F420B44B56DEAC6F04A323BC51F26B1D1C71A39C42D4225BBF1A",
            "failures":[
                "TPIV_FAIL_NAME_SSN4","SUBSCRIBER_UNDER_18","STATE_FED_FAIL","INVALID_ADDRESS"
            ],
            "status":"PENDING_RESOLUTION",
            "eligibilityExpirationDate":"2023-01-15",
            "activeSubscriber":"N",
            "_links":
            {
                "resolution":
                    {
                        "href":"https://api-stg.universalservice.org/ebca-svc/security/getPage?id=2A85924BFE25F420B44B56DEAC6F04A323BC51F26B1D1C71A39C42D4225BBF1A&token=745df36d76756227e35d11512665d9f87e6dcba72d1fbad072c7281a8462bbd1"}
                    }
                }
            ,"date":{
                "$date":{
                    "$numberLong":"1669883415288"
                }
            },"__v":{
                "$numberInt":"0"
            }
         },
         {"_id":
        {"$oid":"638e156dc8baf28a5f8f2445"},
        "firstName":"El",
        "middleName":"Test",
        "lastName":"Esfs",
        "address":"Nyerere Road",
        "state":"AK",
        "city":"Dar Es Salam",
        "zipCode":"32323",
        "urbanizationCode":"",
        "dob":"2019-02-02",
        "ssn4":"3233",
        "tribalId":"",
        "bqpFirstName":"",
        "bqpLastName":"",
        "bqpDob":"",
        "bqpSsn4":"",
        "bqpTribalId":"",
        "alternateId":"",
        "bqpAlternateId":"",
        "eligibilityProgramCode":"E2",
        "publicHousingCode":"",
        "consentInd":"Y",
        "contactPhoneNumber":"3232323443",
        "contactEmail":"dfsljkj@lkfslkjfds",
        "contactAddress":"Nyerere Road",
        "contactCity":"Dar Es Salam",
        "contactState":"AK",
        "contactZipCode":"32323",
        "contactUrbCode":"",
        "repId":"",
        "repNotAssisted":"1",
        "carrierUrl":"https://rural4g.com",
        "status":"PENDING_RESOLUTION",
        "application_id":"B14872-05122",
        "activeSubscriber":"N",
        "usacAPIResponse":{
            "applicationId":"B14872-05122",
            "eligibilityCheckId":"2A85924BFE25F420B44B56DEAC6F04A323BC51F26B1D1C71A39C42D4225BBF1A",
            "failures":[
                "TPIV_FAIL_NAME_SSN4","SUBSCRIBER_UNDER_18","STATE_FED_FAIL","INVALID_ADDRESS"
            ],
            "status":"PENDING_RESOLUTION",
            "eligibilityExpirationDate":"2023-01-15",
            "activeSubscriber":"N",
            "_links":
            {
                "resolution":
                    {
                        "href":"https://api-stg.universalservice.org/ebca-svc/security/getPage?id=2A85924BFE25F420B44B56DEAC6F04A323BC51F26B1D1C71A39C42D4225BBF1A&token=745df36d76756227e35d11512665d9f87e6dcba72d1fbad072c7281a8462bbd1"}
                    }
                }
            ,"date":{
                "$date":{
                    "$numberLong":"1669883415288"
                }
            },"__v":{
                "$numberInt":"0"
            }
         },
         {"_id":
        {"$oid":"638e156dc8baf28a5f8f2445"},
        "firstName":"El",
        "middleName":"Test",
        "lastName":"Esfs",
        "address":"Nyerere Road",
        "state":"AK",
        "city":"Dar Es Salam",
        "zipCode":"32323",
        "urbanizationCode":"",
        "dob":"2019-02-02",
        "ssn4":"3233",
        "tribalId":"",
        "bqpFirstName":"",
        "bqpLastName":"",
        "bqpDob":"",
        "bqpSsn4":"",
        "bqpTribalId":"",
        "alternateId":"",
        "bqpAlternateId":"",
        "eligibilityProgramCode":"E2",
        "publicHousingCode":"",
        "consentInd":"Y",
        "contactPhoneNumber":"3232323443",
        "contactEmail":"dfsljkj@lkfslkjfds",
        "contactAddress":"Nyerere Road",
        "contactCity":"Dar Es Salam",
        "contactState":"AK",
        "contactZipCode":"32323",
        "contactUrbCode":"",
        "repId":"",
        "repNotAssisted":"1",
        "carrierUrl":"https://rural4g.com",
        "status":"PENDING_RESOLUTION",
        "application_id":"B14872-05122",
        "activeSubscriber":"N",
        "usacAPIResponse":{
            "applicationId":"B14872-05122",
            "eligibilityCheckId":"2A85924BFE25F420B44B56DEAC6F04A323BC51F26B1D1C71A39C42D4225BBF1A",
            "failures":[
                "TPIV_FAIL_NAME_SSN4","SUBSCRIBER_UNDER_18","STATE_FED_FAIL","INVALID_ADDRESS"
            ],
            "status":"PENDING_RESOLUTION",
            "eligibilityExpirationDate":"2023-01-15",
            "activeSubscriber":"N",
            "_links":
            {
                "resolution":
                    {
                        "href":"https://api-stg.universalservice.org/ebca-svc/security/getPage?id=2A85924BFE25F420B44B56DEAC6F04A323BC51F26B1D1C71A39C42D4225BBF1A&token=745df36d76756227e35d11512665d9f87e6dcba72d1fbad072c7281a8462bbd1"}
                    }
                }
            ,"date":{
                "$date":{
                    "$numberLong":"1669883415288"
                }
            },"__v":{
                "$numberInt":"0"
            }
         }
    // More people...
  ]

export default function CheckStatus(){
    return (
        <Layout title="Status checks log">

            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">

            <div className="w-80 m-2">
                <label htmlFor="search" className="block text-sm font-medium text-gray-700">
                    Quick search
                </label>
                <div className="relative mt-1 flex items-center border rounded-md">
                    <input
                        type="text"
                        name="search"
                        id="search"
                        className="block p-2 w-full rounded-md border-gray-300 pr-12 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                    <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                        <button type="button" className="">Search</button>
                    </div>
                </div>
            </div>

            <div className="ml-2"> <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24"></svg> Loading ...</div>
                
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Application ID
                    </th>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Names
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Address
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Program
                      <div><small>Eligibility program</small></div>
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Email
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Telephone
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Active 
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Status
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Action
                    </th>
                    {/* <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only">Action</span>
                    </th> */}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {people.map((person) => (
                    <tr key={person.email}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {person.application_id}
                      </td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        <div><small>First name</small> {person.firstName}</div>
                        <div><small>Middle name</small> {person.middleName}</div>
                        <div><small>Last name</small> {person.lastName}</div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                        <div><small>Address</small> {person.address}</div>
                        <div><small>{person.urbanizationCode ? `Urbanization code` : `Zip code`}</small> {person.urbanizationCode ? person.urbanizationCode : person.zipCode}</div>
                        <div><small>City</small> {person.city}</div>
                        <div><small>State</small> {person.state}</div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                        {person.eligibilityProgramCode}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                        {person.contactEmail}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                        {person.contactPhoneNumber}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                        {person.activeSubscriber === "N" ? "No" : "Yes"}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                        {person.status}
                      </td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <a href="#" className="text-indigo-600 hover:text-indigo-900">
                          Details<span className="sr-only">, {person.name}</span>
                        </a>   &nbsp;  | &nbsp; 
                        <a href="#" className="text-indigo-600 hover:text-indigo-900">Fix issues</a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
        </Layout>
    );
}