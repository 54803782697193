import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/auth/Login";
import CheckEligibility from "./pages/check_eligibility/CheckEligibility";
import CheckStatus from "./pages/check_status/CheckStatus";
import ExportSalesforce from "./pages/export_sf/ExportSalesforce";

const MainRouter = () => {
    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Login/>}/>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/check-status" element={<CheckStatus/>}/>
                    <Route path="/check-eligibility" element={<CheckEligibility/>}/>
                    <Route path="/export-salesforce" element={<ExportSalesforce/>}/>
                </Routes>
            </BrowserRouter>
        </div>
    )
}

export default MainRouter;