import MainRouter from "./MainRouter";

function App() {
  return (
    <div>
      <MainRouter/>
    </div>
  );
}

export default App;
