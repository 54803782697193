import Layout from "../../shared_components/Layout";
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import DialogModal from "../../shared_components/DialogModal";
import { useState, useEffect, Fragment } from "react";
import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/24/outline';

import { get_eligibity } from "../../net/eligibility";
import humanizeDuration from "humanize-duration";

let lock_data_check = 0;
let current_page = 1;
let current_application = 0;

export default function CheckEligibility() {
  const [openDialog, setOpenDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const [applications, setApplications] = useState([]);
  const [show_load, setShowLoading] = useState(false);
  const [pageInfo, setPageInfo] = useState({
    current_page: 0,
    total_pages: 0,
    limit: 0
  });

  if (!localStorage.tk_r4g) {
    window.location.href = "/login";
  }

  const handleViewDetails = (id) => {
    console.log(id);
    console.log(applications[id]);
    current_application = id;
    setOpen(true);
  }

  const get_elig = async (kw, page = 1) => {
    setShowLoading(true);
    try {
      const data = await get_eligibity(kw, page);
      if (data) {
        console.log(data);
        setApplications(data.logs);
        setPageInfo({
          current_page: data.currentPage,
          total_pages: data.totalPages,
          limit: data.limit
        })
        elig_data_check = data.logs;
      }
      lock_data_check = 1;
      setShowLoading(false);
    } catch (e) {
      console.log(e);
      setShowLoading(false);
    }
  }

  const getEligibilityProgram = (code) => {
    const elig_programs = [
      { id: 1, value: "E2", title: 'Supplemental Nutrition Assistance Program', description: 'SNAP/Food Stamps/Food Assistance', users: '621 users' },
      { id: 2, value: "E1", title: 'Medicaid', description: 'State of Texas Access Reform(STAR)', users: '1200 users' },
      { id: 3, value: "E3", title: 'Supplemental Security Income(SSI)', description: '', users: '2740 users' },
      { id: 4, value: "E15", title: 'Veterans or Survivors Pension', description: '', users: '2740 users' },
      { id: 5, value: "E4", title: 'Federal Public Housing Assistance', description: '', users: '2740 users' },
      { id: 6, value: "E8", title: 'Bureau of Indian Affairs General Assistance', description: '', users: '2740 users' },
      { id: 7, value: "E9", title: 'Tribal Temporary Assistance for Needy Families (Tribal TANF)', description: '', users: '2740 users' },
      { id: 8, value: "E10", title: 'Food Distribution Program on Indian Reservations (FDPIR)', description: '', users: '2740 users' },
      { id: 9, value: "E11", title: 'Head Start', description: '', users: '2740 users' },
      { id: 10, value: "E13", title: 'Eligibility Based on Income', description: '', users: '2740 users' },
      { id: 11, value: "E50", title: 'School Lunch/Breakfast Program', description: '', users: '2740 users' },
      { id: 12, value: "E51", title: 'Federal Pell Grant', description: '', users: '2740 users' },
      { id: 13, value: "E54", title: 'Special Supplemental Nutrition Program for Women, Infants, and Children (WIC)', description: '', users: '2740 users' },
    ]

    const elig_program = elig_programs.find((item) => item.value === code);
    return elig_program;
  }

  

  const last_kw = "";
  let timeout_lock = null;
  const handleChange = (e) => {
    // if (timeout_lock) {
    //   clearTimeout(timeout_lock);
    // }
    // timeout_lock = setTimeout(function () {
    //   //
    //   last_kw = e.target.value;
    // }, 2000);
    set_search_kw(e.target.value);
  }

  const [search_kw, set_search_kw] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    get_elig(search_kw);
  }

  let elig_data_check = 0;

  useEffect(() => {
    if (lock_data_check === 0) {
      get_elig(last_kw, current_page);
    }
  },[]);

  const goBackward = () => {
    if (current_page > 1) {
      current_page -= 1;
      get_elig(last_kw, current_page);
    }
  }

  const getStatusStyles = (status) => {
    if (status === 'COMPLETE') {
      return 'bg-green-500';
    } else if (status === 'REQUEST_ALREADY_SENT') {
      return 'bg-red-500';
    } else if (status === 'EXISTING_LIFELINE_APP'){
      return 'bg-sky-500';
    } else {
      return 'bg-orange-500';
    }
  }

  const goFoward = () => {
    current_page += 1;
    console.log(current_page);
    get_elig(last_kw, current_page);
  }

  return (
    <>
      <Layout title="Eligibility checks log" className="">
        <div style={{ 'height': (window.screen.height) + 'px', background: 'rgba(0,0,0,0.6)', display: open ? 'block' : 'none' }} className="fixed  z-20 hidden bottom-0 left-0 w-full rounded-xl p-4">
          <div style={{ 'height': (window.screen.height * 0.65) + 'px', boxShadow: '-0px 0px 10px gray' }} className="fixed bg-slate-100 bottom-0 left-0 w-full rounded-xl p-4 pb-20">
            <div className="flex">
              <div className="flex-1 text-3xl">Eligibility details</div>
              <div>
                <button onClick={() => { setOpen(false) }} className="flex-1 rounded-md px-4 py-2 bg-blue-200 hover:bg-blue-300 cursor-pointer">Close</button>
              </div>
            </div>

            <div>
              <div className="text-center hidden">
                Loading details...<br />
                <svg aria-hidden="true" className="mt-2 inline mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                </svg>
              </div>
            </div>

            <div className="w-full h-full overflow-scroll mt-4">
              <div className="md:flex mt-4">
                <div className="md:flex-2 px-4 py-4">
                  <table>
                    <tr><td className="text-sm">Email</td><td className="pl-2 py-2">{applications[current_application] && applications[current_application].contactEmail}</td></tr>
                    <tr><td className="text-sm">Phone number</td><td className="pl-2  py-2">{applications[current_application] && applications[current_application].contactPhoneNumber}</td></tr>
                    {applications[current_application] && applications[current_application].usac_resp && applications[current_application].usac_resp.status && <tr><td className="text-sm">Status</td><td className="pl-2  py-2">{applications[current_application].usac_resp.status && applications[current_application].usac_resp.status} <div className={`w-3 h-3 inline-block rounded-full ${getStatusStyles(applications[current_application].usac_resp.status)}`}></div></td></tr>}
                    {/* <tr><td></td><td className="pl-2  py-2">{applications[current_application] && applications[current_application].status}</td></tr> */}
                    <tr><td></td><td>
                      {applications[current_application] && applications[current_application].usac_resp && applications[current_application].usac_resp.failures && applications[current_application].usac_resp.failures.length > 0 && applications[current_application].usac_resp._links && applications[current_application].usac_resp._links.resolution && (
                        <div className="mt-4">
                          <a target="_blank" className="bg-green-300 hover:bg-green-500 px-4 py-2 rounded-md" href={applications[current_application].usac_resp._links.resolution.href}>Fix the errors</a>
                        </div>
                      )}

                    </td></tr>
                  </table>

                </div>


                <div className="md:flex-1 p-2  md:ml-8">
                  <div className="text-xl">Details</div>
                  <div className="h-full">
                    <table className="w-full">
                      <tr className="bg-slate-100"><td className="py-3 text-left text-gray-500 text-sm">Application ID</td><td className="text-left text-gray-900 pl-2">{applications[current_application] && applications[current_application].usac_resp && applications[current_application].usac_resp.applicationId}</td></tr>
                      <tr><td className="py-3 text-left text-gray-500 text-sm">Eligibility check ID</td><td className="text-left text-gray-900 pl-2">{applications[current_application] && applications[current_application].usac_resp && applications[current_application].usac_resp.eligibilityCheckId}</td></tr>
                      <tr><td className="py-3 text-left text-gray-500 text-sm">First name</td><td className="text-left text-gray-900 pl-2">{applications[current_application] && applications[current_application].firstName}</td></tr>
                      <tr><td className="py-3 text-left text-gray-500 text-sm">Middle name</td><td className="text-left text-gray-900 pl-2">{applications[current_application] && applications[current_application].middleName}</td></tr>
                      <tr><td className="py-3 text-left text-gray-500 text-sm">Last name</td><td className="text-left text-gray-900 pl-2">{applications[current_application] && applications[current_application].lastName}</td></tr>
                      <tr><td className="py-3 text-left text-gray-500 text-sm">Address</td><td className="text-left text-gray-900 pl-2">{applications[current_application] && applications[current_application].address}</td></tr>
                      <tr><td className="py-3 text-left text-gray-500 text-sm">City</td><td className="text-left text-gray-900 pl-2">{applications[current_application] && applications[current_application].city}</td></tr>
                      <tr><td className="py-3 text-left text-gray-500 text-sm">State</td><td className="text-left text-gray-900 pl-2">{applications[current_application] && applications[current_application].state}</td></tr>
                      <tr><td className="py-3 text-left text-gray-500 text-sm">Zip code</td><td className="text-left text-gray-900 pl-2">{applications[current_application] && applications[current_application].zipCode}</td></tr>
                      <tr><td className="py-3 text-left text-gray-500 text-sm">Date of birth</td><td className="text-left text-gray-900 pl-2">{applications[current_application] && applications[current_application].dob}</td></tr>
                      <tr><td className="py-3 text-left text-gray-500 text-sm">SSN</td><td className="text-left text-gray-900 pl-2">{applications[current_application] && applications[current_application].ssn4}</td></tr>
                      <tr><td className="py-3 text-left text-gray-500 text-sm">Tribal ID</td><td className="text-left text-gray-900 pl-2">{applications[current_application] && applications[current_application].tribalId}</td></tr>
                      <tr><td className="py-3 text-left text-gray-500 text-sm">Eligibility program</td><td className="text-left text-gray-900 pl-2">{applications[current_application] && applications[current_application].eligibilityProgramCode}</td></tr>
                      <tr><td className="py-3 text-left text-gray-500 text-sm">Eligibility expiration date</td><td className="text-left text-gray-900 pl-2">{applications[current_application] && applications[current_application].usac_resp && applications[current_application].usac_resp.eligibilityExpirationDate}</td></tr>
                    </table>
                  </div>

                  <div>
                    <div className="text-xl">Errors</div>
                    <div>
                      {applications[current_application] && applications[current_application].usac_resp && applications[current_application].usac_resp.failures && applications[current_application].usac_resp.failures.map((error, index) => (
                        <div key={index} className="text-red-500">{error}</div>
                      ))}
                    </div>

                    {applications[current_application] && applications[current_application].usac_resp && applications[current_application].usac_resp.failures && applications[current_application].usac_resp.failures.length > 0 && applications[current_application].usac_resp._links && applications[current_application].usac_resp._links.resolution && (
                      <div className="mt-4">
                        <a target="_blank" className="bg-green-300 hover:bg-green-500 px-4 py-2 rounded-md" href={applications[current_application].usac_resp._links.resolution.href}>Fix the errors</a>
                      </div>
                    )}

                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div className="overflow-scroll shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">

          <div className="w-80 m-2">
            <label htmlFor="search" className="block text-sm font-medium text-gray-700">
              Quick search
            </label>
            <form onSubmit={handleSubmit}>
              <div className="relative mt-1 flex items-center border rounded-md">
              <input
                type="text"
                name="search"
                id="search"
                onChange={handleChange}
                className="block p-2 w-full rounded-md border-gray-300 pr-12 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
              <div className="absolute inset-y-0 right-0 flex-auto my-0.5 pr-1.5">
                <button type="submit" className="bg-sky-400 hover:bg-sky-500 text-white px-2 text-md rounded-md shadow py-1">Search</button>
              </div>
            </div>
            </form>
            
          </div>

          <div className="flex">
            <div className="flex-1 ml-2">
              <button onClick={goBackward} className="bg-blue-100 hover:bg-blue-200 px-4 py-2 rounded-md">Previous</button>
            </div>
            <div className="flex-1 text-center">
              Page {pageInfo.current_page} of {pageInfo.total_pages} ({pageInfo.limit} per page)
            </div>
            <div className="flex-1 text-right mr-2">
              <button onClick={goFoward} className="bg-blue-100 hover:bg-blue-200 px-4 py-2 rounded-md">Forward</button>
            </div>
          </div>

          <div className="ml-2">
            {show_load && (
              <div className="text-center my-2">
                Loading details...<br />
                <svg aria-hidden="true" className="mt-2 inline mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                </svg>
              </div>)
            }

          </div>

          <table className=" divide-y divide-gray-300 w-full overflow-scroll">
            <thead className="bg-gray-50">
              <tr>
                {/* <th scope="col" className="py-3.5 w-20 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                  Application ID
                </th> */}
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                  Names
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Address
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-40">
                  Program
                  <div><small>Eligibility program</small></div>
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Email
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Telephone
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Active
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Status
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Action
                </th>
                {/* <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                        <span className="sr-only">Action</span>
                      </th> */}
              </tr>
            </thead>
            <tbody className="bg-white">
              {applications.map((person, id) => (
                <>
                  <tr className="border-t border-gray-400">
                    <td colSpan="9" className="px-4 pt-2">
                      <div><small>Application ID : </small>{person.application_id}</div>
                      <div><small>Eligibility check ID : </small>{person.usac_resp && person.usac_resp.eligibilityCheckId}</div>
                      <div><small>Applied : </small> {new Date(person.createdAt).toLocaleDateString()} {new Date(person.createdAt).toLocaleTimeString()}</div>
                      <div><small>Expiration : </small> {person.usac_resp && person.usac_resp.eligibilityExpirationDate}</div>
                      {person.coverage_for && <div><small>Applying for : </small> {person.coverage_for} { person.coverage_for === "home" ? `🏚️` : `📱`}</div>}
                      {person.eligibilityProgramCode === "E50" && person.schoolName && <div><small>School name : </small> {person.schoolName}</div>}
                      {person.usac_resp && person.usac_resp.status && <div><small>Status </small> : {person.usac_resp.status} <div className={`w-3 h-3 inline-block rounded-full ${getStatusStyles(person.usac_resp.status)}`}></div></div>}
                      {person.subscriptions && person.subscriptions.length > 0 && <div><small>Has paid subscriptions : </small> {person.subscriptions.map((sub) => <span className="inline-block bg-gray-100 rounded-full px-3 py-1 text-sm font-semibold text-gray-900 mr-2">{sub.plan_unit_price} USD</span>)}</div>}
                      {<div>Person has received {person.subscription_notifications.length} notifications</div>}
                    </td>
                  </tr>
                  <tr key={person.email}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      <div><small>First name</small> {person.firstName}</div>
                      <div><small>Middle name</small> {person.middleName}</div>
                      <div><small>Last name</small> {person.lastName}</div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                      <div><small>Address</small> {person.address}</div>
                      <div><small>{person.urbanizationCode ? `Urbanization code` : `Zip code`}</small> {person.urbanizationCode ? person.urbanizationCode : person.zipCode}</div>
                      <div><small>City</small> {person.city}</div>
                      <div><small>State</small> {person.state}</div>
                    </td>
                    <td style={{width:"200px"}} className=" px-3 py-4 text-sm text-gray-900">
                      { getEligibilityProgram(person.eligibilityProgramCode).title}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                      {person.contactEmail}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                      {person.contactPhoneNumber}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                      {person.activeSubscriber === "N" ? "No" : "Yes"}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                      {person.status}
                    </td>
                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                      <a href="#" onClick={() => handleViewDetails(id)} className="cursor-pointer text-indigo-600 hover:text-indigo-900">
                        Details
                      </a>
                      {person && person.usac_resp && person.usac_resp.failures && person.usac_resp.failures.length > 0 && person.usac_resp._links && person.usac_resp._links.resolution && (
                        <> &nbsp;  | &nbsp;
                          <a target="_blank" className="text-indigo-600 hover:text-indigo-900" href={person.usac_resp._links.resolution.href}>Fix errors</a>
                        </>
                      )}

                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
      </Layout>
    </>
  );
}